import React, { useEffect, lazy, Suspense, useState } from "react";
import { BrowserRouter, Routes, Route, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, updateUserDetails, getUser } from "./store/slices/authSlice";
import { fetchUserDetails } from "./store/slices/userSlice";
import { Navbar } from "./components/Navbar";

import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { BackTotop } from "./components/BackTotop";
import AdminProtectedRoute from "./routes/AdminProtectedRoute";
import jwtDecode from "jwt-decode";
import { ApiClient } from "./utils/ApiClient";
import { LoadingOutlined } from "@ant-design/icons";
import "./assests/css/style.min.css";
import "./assests/lib/flaticon/font/flaticon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

// Lazy load pages
const Home = lazy(() => import("./pages/Home"));
const Gym = lazy(() => import("./pages/Gym"));
const Feature = lazy(() => import("./pages/Feature"));
const Classes = lazy(() => import("./pages/Classes"));
const Contact = lazy(() => import("./pages/Contact"));
const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Restaurant = lazy(() => import("./pages/Restaurant"));
const ReservationsPage = lazy(() => import("./pages/ReservationsPage"));
const Booking = lazy(() => import("./pages/Booking"));
const Profile = lazy(() => import("./pages/Profile"));
const BookingCalendar = lazy(() => import("./components/BookingCalendar"));

function App() {
  const data0 = `
  888888ba           oo dP       dP 
  88     8b             88       88 
  88aaaa8P' dP    dP dP 88 .d888b88 
  88    8b. 88    88 88 88 88    88 
  88    .88 88.  .88 88 88 88.  .88 
  88888888P  88888P  dP dP  88888P8 
 `;
 
 const data1 = `
 d8888888P                            
     .d8'                            
    .d8'   .d8888b. 88d888b. .d8888b. 
  .d8'     88'   88 88'   88 88ooood8 
 d8'       88.  .88 88    88 88.  ... 
 Y8888888P  88888P' dP    dP  88888P'
 `;
 
 const data2 = `
           d888888P d888888P 
              88       88    
              88       88    
              88       88    
              88       88    
           d888888P    dP   
 `;
 
 const massage=`Welcome to BuildZone IT`
 
 const conditionalConsoleLog = (message, color) => {
   const shouldShowLogs = true; // Set this flag based on your condition
 
   if (shouldShowLogs) {
     console.log(`%c${message}`, `color: ${color};`);
   }
 };
 
 const fetchdata = async() => {
   conditionalConsoleLog(data0, 'red');
   conditionalConsoleLog(data1, 'green');
   conditionalConsoleLog(data2, 'blue');
 }
 
 useEffect(() => {
   fetchdata();
   console.error = function() {};
   console.warn = function() {};
   console.assert = function() {};
   console.info = function() {};
   console.debug = function() {};
   console.info = function() {};  
 }, []);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  useEffect(() => {
    verifyToken();
  }, []);

  const verifyToken = async () => {
    const token = localStorage.getItem("token");
    if (token != null) {
      const decodedJwt = jwtDecode(token);

      const user = {
        isLoggedIn: false,
        accessToken: localStorage.getItem("token"),
        refreshToken: localStorage.getItem("refreshToken"),
        name: decodedJwt.name,
        id: decodedJwt._id,
        email: decodedJwt.email,
        role: decodedJwt.role,
      };

      await ApiClient.post(`/user/verify`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          user.isLoggedIn = true;
          dispatch(updateUserDetails(user));
          dispatch(fetchUserDetails());
        })
        .catch((err) => {
          dispatch(logout());
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="d-flex min-vh-100 min-vw-100 justify-content-center align-items-center">
          <LoadingOutlined style={{ fontSize: "64px", color: "#e31c25" }} />
        </div>
      ) : (
        <div>
          <Navbar />
          <div className="min-vh-100">
            <Suspense
              fallback={
                <div className="d-flex min-vh-100 min-vw-100 justify-content-center align-items-center">
                  <LoadingOutlined style={{ fontSize: "64px", color: "#e31c25" }} />
                </div>
              }
            >
              <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/" element={<Home />} />
                <Route
                  element={
                    <div>   
                      <Header/>
                                       <Outlet />
                    </div>
                  }
                >
                  <Route element={<AdminProtectedRoute />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                  </Route>
                  <Route path="/gym" element={<Gym />} />
                  <Route path="/feature" element={<Feature />} />
                  <Route path="/classes" element={<Classes />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/restaurant" element={<Restaurant />} />
                  <Route path="/booking" element={<ReservationsPage />} />
                  <Route
                    path="/booking/badminton"
                    element={user?.isLoggedIn ? <Booking type="badminton" /> : <Login />}
                  />
                  <Route
                    path="/booking/cricket"
                    element={user?.isLoggedIn ? <Booking type="cricket" /> : <Login />}
                  />
                  <Route
                    path="/booking/gym"
                    element={user?.isLoggedIn ? <Booking type="gym" /> : <Login />}
                  />
                  <Route
                    path="/booking/swimmingpool"
                    element={<Booking type="pool" />}
                  />
                  <Route
                    path="/booking/swimmingpool/calendar"
                    element={user?.isLoggedIn ? <BookingCalendar type="pool" /> : <Login />}
                  />
                  <Route
                    path="/profile/:id"
                    element={user?.isLoggedIn ? <Profile /> : <Login />}
                  />
                </Route>
                <Route
                  path="*"
                  element={
                    <div className="min-vh-100 min-vw-100 d-flex justify-content-center align-items-center">
                      <h1>404 Not Found</h1>
                    </div>
                  }
                />
              </Routes>
            </Suspense>
          </div>
          <Footer />
          <BackTotop />
        </div>
      )}
    </>
  );
}

export default App;
